@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', sans-serif !important;
}

h1, h2{
  font-family: 'Poppins', sans-serif !important;
}

h3, h4, h5, h6{
  font-family: 'Nunito', sans-serif !important;
}

table{
  font-family: 'Nunito', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/********* ESTILOS PARA EL CAMBIOS EN EL ESTILO DEL SCROLL *******/

/* Ancho del scroll */
/* ::-webkit-scrollbar {
  width: 12px;
} */

/* Color del fondo del scroll */
/* ::-webkit-scrollbar-track {
  background-color: #D9D9E3;
} */

/* Color de la barra de desplazamiento */
/* ::-webkit-scrollbar-thumb {
  background-color: #888;
} */

/* Esquinas redondeadas en la barra de desplazamiento */
/* ::-webkit-scrollbar-thumb {
  border-radius: 5px;
} */