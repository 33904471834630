.card {
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9e9e9b8;
  min-width: 200px;
  min-height: 100px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  width: 100%;
  height: fit-content;
}

.card.full {
  height: 100%;
}
.card-button {
  width: 100%;
  padding: 8px 20px !important;
  border-radius: 0px 0px 15px 15px;
  text-align: center;
  cursor: pointer !important;
}

.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
}

.card-button-primary {
  width: 100%;
  padding: 8px 20px !important;
  border-radius: 0px 0px 15px 15px;
  text-align: center;
  cursor: pointer !important;
}

.card-button.withHover:hover {
  background-color: rgb(218, 218, 218);
}

.card-button-primary.withHover:hover {
  background-color: rgb(218, 218, 218);
}

.card-content {
  font-size: 14px;
  padding: 25px;
  text-align: center;
  color: #515151;
  height: fit-content;
  line-height: 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.card-title-content {
  font-size: 17px;
  padding: 15px 20px;
  text-align: start;
  color: #515151;
  height: fit-content;
  line-height: 20px;
}

.pagination-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageTitle {
  font-size: 28px;
  font-weight: 600;
  text-align: start;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-container .inputLabel {
  font-weight: 600;
  color: #554e4e;
  font-size: 14px;
  line-height: 1.25;
  padding-bottom: 4px;
}

.helperText {
  font-size: 0.7rem;
  text-align: end;
  padding: 0px 10px;
}

.applyFilter-button {
  font-size: 0.7rem !important;
  padding: 4px 10px !important;
  font-weight: bold !important;
  align-self: self-end;
  line-height: 15px;
  min-width: 100px !important;
  background-color: #2c353d !important;
  color: white !important;
}

.applyFilter-button:hover {
  background-color: #2c353d;
}

.applyFilter-button:disabled {
  color: rgb(101, 101, 101) !important;
}

.applyFilter-button {
  &.mobile,
  &.tablet {
    width: 100%;
    min-width: 100% !important;
  }
}

thead th {
  text-align: center !important;
  padding: 15px 15px 5px 15px !important;
  font-weight: 600 !important;
}

.MuiInputLabel-outlined {
  font-size: 12px !important;
}



.MuiInputBase-input {
  font-size: 0.85rem !important;
}

input {
  box-sizing: initial;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: 0.85rem !important;
}

.contentModal-title-section {
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 16px;
  align-items: center;
  font-weight: 600;
}

.nospace {
  column-gap: 0px;
  row-gap: 0px;
}

.label-in-outlinedInput[class*="MuiFormLabel-root-MuiInputLabel-root"] {
  transform: translate(14px, 9px) scale(1) !important;
  -webkit-transform: translate(14px, 9px) scale(1) !important;
}

.label-in-outlinedInput[class*="MuiFormLabel-root-MuiInputLabel-root"].Mui-focused {
  transform: translate(14px, -9px) scale(0.75) !important;
  -webkit-transform: translate(14px, -9px) scale(0.75) !important;
}

.card-information {
  line-height: 18px;
  font-size: 14px;
  flex: 1 1 0%;
  text-align: start;
}

.informationDialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;

  & .information-content {
    flex: 1 1;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 325px;
  }

  & .information-proced {
    color: gray;
  }
}

.skip-Button {
  color: #4eaecd !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
}

.modal-subtitle {
  font-weight: 600;
  font-size: 1.1rem;
  align-self: start;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-search {
  width: 100%;
  padding: 8px;
  border: 1px solid #cecece;
  border-radius: 3px;
  font-size: 13px;
}

[type="search"] {
  outline-offset: unset !important;
}

.no-data {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #a5a5a5;
  align-items: center;
}

.option-imagen-title-container {
  min-height: 150px;
  position: relative;
}

.image-title-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  opacity: 0.4;
}

.button-tooltip-icon {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
}

.bg-meeting {
  background-image: url("/public/assets/images/fondo.webp");
}

.strikethrough-diagonal {
  position: relative;
}

.strikethrough-diagonal:before {
  position: absolute;
  content: "";
  left: 0;
  top: 45%;
  right: 0;
  border-top: 1px solid;
  border-color: #111827;
  -webkit-transform: skewY(-10deg);
  -moz-transform: skewY(-10deg);
  transform: skewY(-10deg);
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

.parpadea-rapido {
  animation-name: parpadeo;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@media only screen and (max-width: 600px) {
  .informationDialog-container .icon-saveSetting {
    display: none !important;
  }
  .informationDialog-container .information-content {
    min-width: unset;
  }
  .informationDialog-container .icon-show-saveSetting {
    font-size: 65 !important;
  }
  .applyFilter-button {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .contentModal-title-section {
    font-size: 14px;
    gap: 5px;
  }
  .pageTitle {
    font-size: 24px;
  }

  .card-title-content {
    font-size: 14px;
    padding: 15px 15px;
  }

  .input-container .inputLabel {
    font-size: 12px;
  }
}

/* Para hacer zoom a los iframe */
.smallHTML html {
  zoom: 0.6;
  border: #404040;
}

/* SHINE EFFECT FOR SET UP NEW MEETING CARD */
.card-shine-effect {
  --shine-deg: -45deg;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    var(--shine-deg),
    transparent 20%,
    transparent 40%,
    rgba(206, 206, 206, 0.41) 60%,
    rgb(165 165 165 / 10%) 65%,
    transparent 70%,
    transparent 100%
  );
  background-size: 250% 250%, 100% 100%;
  transition: background-position 0s ease;
}


/***** CAMBIAR EL ASPECTO DEL SCROLL *******/
/* Cambia el tamaño de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 12px; /* Anchura para scroll vertical */
  height: 12px; /* Altura para scroll horizontal */
}

/* Cambia el color de fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Cambia el color y la forma del "thumb" o la parte que se desplaza */
::-webkit-scrollbar-thumb {
  background-color: #aeaeaee9 ; /* Color del thumb #888 */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #f1f1f1; /* Bordes del thumb */
}

/* Cambia el color del "thumb" cuando el usuario lo está usando (hover) */
::-webkit-scrollbar-thumb:hover {
  background-color: #9a9a9a;
}