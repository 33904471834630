

#main-pnf{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: #888;
}

.fof{
	  display: table-cell;
	  vertical-align: middle;
}

.fof .fof-title{
	  font-size: 8em;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

.fof .fof-subtitle{
    font-size: 1.5em;
    padding-bottom: 7px;
}

.divider{
    padding: 5px 0px 45px 0px;
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
}

.btn-goback{
    border: 1px solid #65c3e8;
    padding: 10px 25px;
    width: 160px;
    background-color: #65c3e8;
    border-radius: 5px;
    font-size: 0.75em;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-goback:hover{
    border: 1px solid #9bdaf3;
    background-color: #9bdaf3;
}

/* @keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}  */