/* STYLES FOM MOBILE */

.container-modal-1{
    padding: 4px;
}

.container-modal-2{
    padding: 8px;
}

.form-container-col{
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    min-width: 200px;
}

.form-container-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2px;
}

.form-label{
    font-weight: 600;
    color: #554e4e;
    font-size: 12px;
    line-height: 1.25;
}

p.helper-text,
span.helper-text{
    font-size: 11px;    
    line-height: 1.25;
    padding-top: 2px;
    padding-right: 4px;
    padding-left: 4px;
}

.error{
    color: rgb(183, 0, 0);
}

.MuiSelect-select{
    padding:8.5px 32px 8.5px 14px !important;
}

@media  (min-width:640px){
    .form-label{
        font-weight: 600;
        color: #554e4e;
        font-size: 13px;
        line-height: 1.25;
    }
    p.helper-text,
    span.helper-text{
        font-size: 12px;    
    }
}

@media  (min-width:768px){
    .form-label{
        font-weight: 600;
        color: #554e4e;
        font-size: 14px;
        line-height: 1.25;
    }
}
